//
// Paragraph styling
//

// Imports
@import "../../../styles/variables";

// Styling
.paragraph__work {
  position: relative;
}

.paragraph {
  color: $color-white;
  font-family: $font-sansserif;
  font-size: $typography-md;
  letter-spacing: $spacing-xxs;
  line-height: 1.5;
  margin: $spacing-md 0 $spacing-xl 0;
  transition: all ease $time-sm;

  &__right {
    text-align: left;
    margin: $spacing-md 0 $spacing-xl 0;

    @media (min-width: $breakpoint-sm) {
      text-align: right;
      margin: $spacing-md 0 $spacing-xl $spacing-xl;
    }

    @media (min-width: $breakpoint-lg) {
      text-align: right;
      margin: $spacing-md 0 $spacing-xl $spacing-xl;
    }
  }

  &__small {
    margin: $spacing-md 0 $spacing-md 0;
  }

  &--dark {
    color: $color-black;
  }

  &--light {
    color: $color-grey;
  }

  &__workItem {
    display: flex;
    justify-content: flex-end;
    font-size: $typography-sm;
    margin: 0;
  }
}
