//
// Menu styling
//

// Imports
@import "../../../styles/variables";

// Styling
.menu {
  display: flex;
  justify-content: space-between;
  margin: $spacing-md;

  &__item {
    display: inline-block;
    font-family: $font-sansserif;
    font-size: $typography-sm;
    letter-spacing: $spacing-xxs;
    text-transform: uppercase;
    color: $color-black;

    &--dark {
      color: $color-white;

      &:after {
        background-color: $color-white !important;
      }

      &:visited {
        color: $color-white !important;
      }
    }

    &:after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: $color-black;
      transform: scaleX(0);
      transform-origin: left center;
      transition: all $time-sm $cubic-bezier;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
        transform-origin: right center;
      }
    }

    &:visited {
      color: $color-black;
    }
  }
}
