//
// Icon styling
//

// Import
@import "../../../styles/variables";

// Styling
.icon {
  &__arrow-down {
    position: relative;
    width: $typography-md;
    height: $typography-md;
    left: 50%;
    animation: bounce 2s ease both infinite;
  }
}

// Animation
@keyframes bounce {
  from {
    transform: translateY(-120px);
    opacity: 1;
  }
  to {
    transform: translateY(-80px);
    opacity: 0;
  }
}
