@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);
.menu{display:flex;justify-content:space-between;margin:1em}.menu__item{display:inline-block;font-family:"Montserrat",sans-serif;font-size:.8em;letter-spacing:.05em;text-transform:uppercase;color:#000}.menu__item--dark{color:#fff}.menu__item--dark:after{background-color:#fff !important}.menu__item--dark:visited{color:#fff !important}.menu__item:after{content:"";display:inline-block;width:100%;height:1px;background-color:#000;transform:scaleX(0);transform-origin:left center;transition:all .6s cubic-bezier(0, 0, 0, 1)}.menu__item:hover:after{transform:scaleX(1);transform-origin:right center}.menu__item:visited{color:#000}

.background{position:fixed;width:100vw;height:calc(1vh * 100);height:calc(var(--vh, 1vh) * 100);left:0;top:0;background-color:#d9d3c7;pointer-events:none;z-index:-1}.background--dark{background-color:#000}.background:after{content:"";position:fixed;width:300%;height:300%;top:-110%;left:-50%;background-image:url(/static/media/noise.2c3ccc8b.png);opacity:.3;-webkit-animation:noise 10s steps(8) infinite;animation:noise 10s steps(8) infinite}@-webkit-keyframes noise{0%{transform:translate(0px, 0px)}10%{transform:translate(-100px, 100px)}20%{transform:translate(150px, -100px)}30%{transform:translate(-100px, 100px)}40%{transform:translate(100px, -150px)}50%{transform:translate(-100px, 200px)}60%{transform:translate(-200px, -100px)}70%{transform:translate(50px, 100px)}80%{transform:translate(100px, -150px)}90%{transform:translate(0px, 200px)}100%{transform:translate(-100px, 100px)}}@keyframes noise{0%{transform:translate(0px, 0px)}10%{transform:translate(-100px, 100px)}20%{transform:translate(150px, -100px)}30%{transform:translate(-100px, 100px)}40%{transform:translate(100px, -150px)}50%{transform:translate(-100px, 200px)}60%{transform:translate(-200px, -100px)}70%{transform:translate(50px, 100px)}80%{transform:translate(100px, -150px)}90%{transform:translate(0px, 200px)}100%{transform:translate(-100px, 100px)}}

.name{font-family:"Montserrat",sans-serif;font-size:1em;text-align:center;text-transform:uppercase;font-weight:100}.name--light{color:#000;font-size:1em;margin:0;opacity:.6}.name--dark{color:#fff;font-size:1em;margin:0;opacity:.6}

.container{display:flex;justify-content:center;margin:2em 1em 1em 1em}@media (min-width: 43em){.container{margin:2em 1em 1em 1em}}@media (min-width: 62em){.container{margin:2em 8em 1em 8em}}.container__no-margin{margin:0}.container__row{display:flex;flex-direction:column;width:100%}@media (min-width: 43em){.container__row{flex-direction:row}}@media (min-width: 62em){.container__row{flex-direction:row}}.container__column{width:100%}@media (min-width: 43em){.container__column{width:60%}}@media (min-width: 62em){.container__column{width:50%}}.container__work{display:flex;width:100%;height:100%;flex-direction:column}@media (min-width: 43em){.container__work{flex-direction:column}}@media (min-width: 62em){.container__work{flex-direction:row}}.item{width:100%}@media (min-width: 43em){.item{width:10%}}@media (min-width: 62em){.item{width:10%}}.item__small{position:relative;flex-grow:4}@media (min-width: 43em){.item__small{position:relative}}@media (min-width: 62em){.item__small{position:relative}}.item__medium{flex-grow:2;align-self:flex-end;margin:8em 1em}.item__big{flex-grow:10}.item__collapse{position:absolute;opacity:.3}@media (min-width: 43em){.item__collapse{position:relative;top:0%;opacity:.6}}@media (min-width: 62em){.item__collapse{position:relative;top:0%;opacity:1}}.item__work{flex:1 1}.item__work__right{margin:0}@media (min-width: 43em){.item__work__right{margin:0}}@media (min-width: 62em){.item__work__right{margin:1em 8em 0 4em;align-self:flex-end}}.item__work__left{position:relative;display:block;margin:0}@media (min-width: 43em){.item__work__left{position:relative;display:block;margin:0}}@media (min-width: 62em){.item__work__left{margin:1em}}.item__work-detail{top:10%}@media (min-width: 43em){.item__work-detail{position:relative;top:10%}}@media (min-width: 62em){.item__work-detail{position:relative;top:10%}}

.logo{stroke-dasharray:400;stroke-dashoffset:400;fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:1;-webkit-animation:draw 2s linear 0.5s forwards;animation:draw 2s linear 0.5s forwards}.logo--white{stroke:#fff}@-webkit-keyframes draw{to{stroke-dashoffset:0}}@keyframes draw{to{stroke-dashoffset:0}}

.divider{display:flex}.divider__max-width{max-width:200px}.divider__border{flex-grow:9;height:1px;background:#707070;margin:.4em 0 0 0;transition:all ease .6s}.divider__border__hover{margin:.4em 0 0 2em}.divider__title{color:#707070;font-family:"Montserrat",sans-serif;font-size:.8em;text-transform:uppercase;font-weight:normal;margin:0 0 0 1em}.divider__title__no-margin{margin:1em 0 0 0}.divider__title__hidden{display:block}@media (min-width: 43em){.divider__title__hidden{display:none}}@media (min-width: 62em){.divider__title__hidden{display:none}}.divider__work{color:#707070;font-family:"Montserrat",sans-serif;text-transform:capitalize;font-size:.8em;display:inline-block;transition:transform 500ms}.divider__work span{display:inline-block;position:relative;overflow:hidden}

.paragraph__work{position:relative}.paragraph{color:#fff;font-family:"Montserrat",sans-serif;font-size:1em;letter-spacing:.05em;line-height:1.5;margin:1em 0 4em 0;transition:all ease .6s}.paragraph__right{text-align:left;margin:1em 0 4em 0}@media (min-width: 43em){.paragraph__right{text-align:right;margin:1em 0 4em 4em}}@media (min-width: 62em){.paragraph__right{text-align:right;margin:1em 0 4em 4em}}.paragraph__small{margin:1em 0 1em 0}.paragraph--dark{color:#000}.paragraph--light{color:#707070}.paragraph__workItem{display:flex;justify-content:flex-end;font-size:.8em;margin:0}

a{text-decoration:none;cursor:pointer}.link{margin:1em 0 1em 0;overflow:hidden}.link a{color:#fff;font-family:"Montserrat",sans-serif;font-size:1em;text-transform:capitalize}.link span{position:relative;display:inline-block;transition:transform .6s}.link span:before{position:absolute;white-space:nowrap;top:100%;content:attr(data-hover);font-weight:500}.link span:hover,.link span:focus{transform:translateY(-100%)}

.image{max-width:100%;height:auto;object-fit:cover;margin:1em 0}.image__header{width:100%;height:400px;margin:0}.image__work{width:100%;height:100%;object-fit:cover;-webkit-filter:opacity(.6);filter:opacity(.6);transition:all ease .6s}.image__hover{-webkit-filter:opacity(.8);filter:opacity(.8)}

.title{font-family:"Libre Caslon Display",serif;text-align:center;text-transform:capitalize;font-weight:normal}.title__center{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.title__up{margin:0 2em 0 0}.title__down{margin:-.4em 0 0 0}.title__big{font-size:6em}.title__small{font-size:2em}@media (min-width: 43em){.title__small{font-size:3em}}@media (min-width: 62em){.title__small{font-size:3em}}.title--light{color:#000}.title--dark{color:#fff}.title__project{position:absolute;z-index:2;font-size:6em;color:transparent;left:50%;transform:translate(-50%, 100%);-webkit-text-stroke:1px #000;transition:all ease .6s}@media (min-width: 62em){.title__project{left:50%;margin:1em 0;transform:translate(0%, 0%)}.title__project__hover{transform:scale(1.1)}}.title__project-detail{z-index:2;font-size:6em;color:transparent;-webkit-text-stroke:1px #fff;overflow:hidden}

.work{margin:8em 0 calc(8em * 2) 0}.work:last-child{margin:0 0 8em 0}

.icon__arrow-down{position:relative;width:1em;height:1em;left:50%;-webkit-animation:bounce 2s ease both infinite;animation:bounce 2s ease both infinite}@-webkit-keyframes bounce{from{transform:translateY(-120px);opacity:1}to{transform:translateY(-80px);opacity:0}}@keyframes bounce{from{transform:translateY(-120px);opacity:1}to{transform:translateY(-80px);opacity:0}}

