//
// Background styling
//

// Imports
@import "../../../styles/variables";

// Styling
.background {
  position: fixed;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  left: 0;
  top: 0;
  background-color: $color-sand;
  pointer-events: none;
  z-index: -1;

  &--dark {
    background-color: $color-black;
  }

  &:after {
    content: "";
    position: fixed;
    width: 300%;
    height: 300%;
    top: -110%;
    left: -50%;
    background-image: url("../../../images/noise.png");
    opacity: $opacity-xl;
    animation: noise 10s steps(8) infinite;
  }
}

// Animations
@keyframes noise {
  0% {
    transform: translate(0px, 0px);
  }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px, 100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translate(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}
