//
// Divider styling
//

// Imports
@import "../../../styles/variables";

// Styling
.divider {
  display: flex;

  &__max-width {
    max-width: 200px;
  }

  &__border {
    flex-grow: 9;
    height: 1px;
    background: $color-grey;
    margin: $spacing-xs 0 0 0;
    transition: all ease $time-sm;

    &__hover {
      margin: $spacing-xs 0 0 $spacing-lg;
    }
  }

  &__title {
    color: $color-grey;
    font-family: $font-sansserif;
    font-size: $typography-sm;
    text-transform: uppercase;
    font-weight: normal;
    margin: 0 0 0 $spacing-md;

    &__no-margin {
      margin: $spacing-md 0 0 0;
    }

    &__hidden {
      display: block;

      @media (min-width: $breakpoint-sm) {
        display: none;
      }

      @media (min-width: $breakpoint-lg) {
        display: none;
      }
    }
  }

  &__work {
    color: $color-grey;
    font-family: $font-sansserif;
    text-transform: capitalize;
    font-size: $typography-sm;
    display: inline-block;
    transition: transform 500ms;

    span {
      display: inline-block;
      position: relative;
      overflow: hidden;
    }
  }
}
