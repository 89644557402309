//
// Title styling
//

// Imports
@import "../../../styles/variables";

// Styling
.title {
  font-family: $font-serif;
  text-align: center;
  text-transform: capitalize;
  font-weight: normal;

  &__center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__up {
    margin: 0 $spacing-lg 0 0;
  }

  &__down {
    margin: -$spacing-xs 0 0 0;
  }

  &__big {
    font-size: $typography-xxl;
  }

  &__small {
    font-size: 2em;

    @media (min-width: $breakpoint-sm) {
      font-size: $typography-xl;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: $typography-xl;
    }
  }

  &--light {
    color: $color-black;
  }

  &--dark {
    color: $color-white;
  }

  &__project {
    position: absolute;
    z-index: 2;
    font-size: $typography-xxl;
    color: transparent;
    left: 50%;
    transform: translate(-50%, 100%);
    -webkit-text-stroke: 1px $color-black;
    transition: all ease $time-sm;

    @media (min-width: $breakpoint-lg) {
      left: 50%;
      margin: $spacing-md 0;
      transform: translate(0%, 0%);
      &__hover {
        transform: scale(1.1);
      }
    }
  }

  &__project-detail {
    z-index: 2;
    font-size: $typography-xxl;
    color: transparent;
    -webkit-text-stroke: 1px $color-white;
    overflow: hidden;
  }
}
