//
// Color variables
//

$color-sand: #d9d3c7;
$color-grey: #707070;
$color-purple: #a78a8a;

$color-black: #000;
$color-white: #fff;

//
// Spacing variables
//

$spacing-xxs: 0.05em;
$spacing-xs: 0.4em;
$spacing-sm: 0.8em;
$spacing-md: 1em;
$spacing-lg: 2em;
$spacing-xl: 4em;
$spacing-xxl: 8em;

// Measurements

$time-xs: 150ms;
$time-sm: 0.6s;

// Opacity

$opacity-sm: 0.8;
$opacity-md: 0.6;
$opacity-lg: 0.4;
$opacity-xl: 0.3;

// Cubic Bezier

$cubic-bezier: cubic-bezier(0, 0, 0, 1);

// Breakpoint

$breakpoint-sm: 43em;
$breakpoint-lg: 62em;

//
// Typography variables
//

// Size

$typography-xxs: 0.2em;
$typography-xs: 0.6em;
$typography-sm: 0.8em;
$typography-md: 1em;
$typography-lg: 1.5em;
$typography-xl: 3em;
$typography-xxl: 6em;

// Weight

$typography-medium: 400;
$typography-bold: 700;

//
// Font variables
//

// prettier-ignore
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
// prettier-ignore
@import url(https://fonts.googleapis.com/css2?family=Libre+Caslon+Display&display=swap);

$font-sansserif: "Montserrat", sans-serif;
$font-serif: "Libre Caslon Display", serif;
