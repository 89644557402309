//
// Link styling
//

// Imports
@import "../../../styles/variables";

// Styling

a {
  text-decoration: none;
  cursor: pointer;
}

.link {
  margin: $spacing-md 0 $spacing-md 0;
  overflow: hidden;

  a {
    color: $color-white;
    font-family: $font-sansserif;
    font-size: $typography-md;
    text-transform: capitalize;
  }

  span {
    position: relative;
    display: inline-block;
    transition: transform $time-sm;

    &:before {
      position: absolute;
      white-space: nowrap;
      top: 100%;
      content: attr(data-hover);
      font-weight: 500;
    }

    &:hover,
    &:focus {
      transform: translateY(-100%);
    }
  }
}
