//
// Image styling
//

// Imports
@import "../../../styles/variables";

// Styling
.image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  margin: $spacing-md 0;

  &__header {
    width: 100%;
    height: 400px;
    margin: 0;
  }

  &__work {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: opacity($opacity-md);
    transition: all ease $time-sm;
  }

  &__hover {
    filter: opacity($opacity-sm);
  }
}
