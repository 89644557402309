//
// Container styling
//

// Imports
@import "../../../styles/variables";

// Styling
.container {
  display: flex;
  justify-content: center;
  margin: $spacing-lg $spacing-md $spacing-md $spacing-md;

  @media (min-width: $breakpoint-sm) {
    margin: $spacing-lg $spacing-md $spacing-md $spacing-md;
  }

  @media (min-width: $breakpoint-lg) {
    margin: $spacing-lg $spacing-xxl $spacing-md $spacing-xxl;
  }

  &__no-margin {
    margin: 0;
  }

  &__row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $breakpoint-sm) {
      flex-direction: row;
    }

    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }

  &__column {
    width: 100%;

    @media (min-width: $breakpoint-sm) {
      width: 60%;
    }

    @media (min-width: $breakpoint-lg) {
      width: 50%;
    }
  }

  &__work {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    @media (min-width: $breakpoint-sm) {
      flex-direction: column;
    }

    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }
}

.item {
  width: 100%;

  @media (min-width: $breakpoint-sm) {
    width: 10%;
  }

  @media (min-width: $breakpoint-lg) {
    width: 10%;
  }

  &__small {
    position: relative;
    flex-grow: 4;

    @media (min-width: $breakpoint-sm) {
      position: relative;
    }

    @media (min-width: $breakpoint-lg) {
      position: relative;
    }
  }

  &__medium {
    flex-grow: 2;
    align-self: flex-end;
    margin: $spacing-xxl $spacing-md;
  }

  &__big {
    flex-grow: 10;
  }

  &__collapse {
    position: absolute;
    opacity: $opacity-xl;

    @media (min-width: $breakpoint-sm) {
      position: relative;
      top: 0%;
      opacity: $opacity-md;
    }

    @media (min-width: $breakpoint-lg) {
      position: relative;
      top: 0%;
      opacity: 1;
    }
  }

  &__work {
    flex: 1 1 0;

    &__right {
      margin: 0;

      @media (min-width: $breakpoint-sm) {
        margin: 0;
      }

      @media (min-width: $breakpoint-lg) {
        margin: $spacing-md $spacing-xxl 0 $spacing-xl;
        align-self: flex-end;
      }
    }

    &__left {
      position: relative;
      display: block;
      margin: 0;

      @media (min-width: $breakpoint-sm) {
        position: relative;
        display: block;
        margin: 0;
      }

      @media (min-width: $breakpoint-lg) {
        margin: $spacing-md;
      }
    }
  }

  &__work-detail {
    top: 10%;

    @media (min-width: $breakpoint-sm) {
      position: relative;
      top: 10%;
    }

    @media (min-width: $breakpoint-lg) {
      position: relative;
      top: 10%;
    }
  }
}
