//
// Name styling
//

// Imports
@import "../../../styles/variables";

// Styling
.name {
  font-family: $font-sansserif;
  font-size: $typography-md;
  text-align: center;
  text-transform: uppercase;
  font-weight: 100;

  &--light {
    color: $color-black;
    font-size: $typography-md;
    margin: 0;
    opacity: $opacity-md;
  }

  &--dark {
    color: $color-white;
    font-size: $typography-md;
    margin: 0;
    opacity: $opacity-md;
  }
}
