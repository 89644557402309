//
// Logo styling
//

// Imports
@import "../../../styles/variables";

// Styling
.logo {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 1;
  animation: draw 2s linear 0.5s forwards;

  &--white {
    stroke: #fff;
  }
}

// Animation
@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
